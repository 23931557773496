import * as React from "react";
import HcpLayout from "../components/hcp/layout";
import { Row, Col } from "react-grid-system";
import { Seo } from "../components/seo";
import useDeviceSize from "../hooks/use-device-size";
import { TfiAngleRight } from "react-icons/tfi";
import "../sass/_orphan.scss";
import "./treatment-goals.scss";
import bannerImg from "../images/why-wakix/hero-about.webp";
import bannerImgMobile from "../images/why-wakix/hero-about-mobile.webp";
import pdfThumb from "../images/orphan/thumb-pdf-treatment-goals-tool.webp";
import iconPencil from "../images/icons/icon-pencil.svg";
import heroName from "../images/why-wakix/hero-about-name.webp";

const TreatmentGoalsPage = () => {
    const deviceSizes = useDeviceSize();

    return (
        <HcpLayout
            pageClass="treatment-goals-page orphan-page"
            copyRightYear="2024"
            jobCode="US-WAK-2400358/Aug 2024"
            noStickyHCPLocatorCTA={true}
        >
            <div className="hero-wrapper row-full">
                <div className="hero-content-wrapper">
                    <div className="hero-banner inner bg-cover">
                        {deviceSizes?.smUp ? (
                            <img
                                src={bannerImg}
                                className="banner-mobile"
                                alt="Setting your treatment goals"
                            />
                        ) : (
                            <img
                                src={bannerImgMobile}
                                className="banner-desktop"
                                alt="Setting your treatment goals"
                            />
                        )}
                        <div className="centered-content">
                            <div className="hero-name">
                                <img
                                    src={heroName}
                                    alt="Chris - Living with narcolepsy Taking WAKIX since 2021"
                                />
                            </div>
                        </div>
                        {/* <div className="banner-text show-desktop">
                  <div className="centered-content">
                    <h1 className="h1-lrg">Setting Your <br className="show-desktop" />Treatment Goals</h1>
                  </div>
                </div> */}
                    </div>
                </div>
            </div>

            <section className="centered-content">
                <div className="page-heading ph-btm">
                    <h1 className="font-40">Setting Your Treatment Goals</h1>
                </div>
            </section>

            <section className="gray-bg cta-block">
                <div className="centered-content">
                    <Row className="goals-row" align="center">
                        <Col xs={12} md={7} lg={8} xl={9} className="goals-col">
                            <div className="content-copy">
                                <p
                                    className="btm-spacer"
                                    style={{ marginTop: "0" }}
                                >
                                    Establishing treatment goals is a good way
                                    to know where you hope to be headed during
                                    treatment with WAKIX. When you're thinking
                                    about what kind of goals you want to set,
                                    think about why your healthcare provider
                                    prescribed WAKIX.
                                </p>
                                <div className="inner-col-wrapper">
                                    <img
                                        src={iconPencil}
                                        alt="Pencil icon"
                                        className="img-icon"
                                    />
                                    <p className="strong">
                                        Then take some time to write down a
                                        couple of specific goals to work toward.
                                    </p>
                                </div>
                                <p>
                                    You can track your progress over time and
                                    reflect on how achieving your goals makes
                                    you feel.
                                </p>
                                <p>
                                    Be sure to share this information with your
                                    healthcare provider as part of the
                                    discussion about your treatment.
                                </p>
                                <p className="strong btm-spacer">
                                    Download this guide to think about, set, and
                                    reflect on your treatment goals.
                                </p>
                                <div className="btn-wrap show-mobile">
                                    <a
                                        href="/pdf/WAKIX-setting-treatment-goals.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="btn btn-primary btn-primary--md"
                                    >
                                        Get started
                                        <TfiAngleRight />
                                    </a>
                                </div>
                            </div>
                        </Col>
                        <Col
                            xs={12}
                            md={5}
                            lg={4}
                            xl={3}
                            className="goals-col goals-col-2"
                        >
                            <img
                                src={pdfThumb}
                                alt="Setting treatment goals that matter to you thumbnail"
                                className="box-shadow"
                            />
                        </Col>
                    </Row>
                    <Row justify="end">
                        <Col md={7} lg={8} xl={9}>
                            <div className="btn-wrap show-desktop ml-lg-96">
                                <a
                                    href="/pdf/WAKIX-setting-treatment-goals.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn btn-primary btn-primary--md"
                                >
                                    Get started
                                    <TfiAngleRight />
                                </a>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
        </HcpLayout>
    );
};

export default TreatmentGoalsPage;

export const Head = () => (
    <Seo
        title="Talking With Your Healthcare Provider | WAKIX® (pitolisant) tablets"
        description="Use this discussion guide to help prepare for your appointment with your healthcare provider."
        keywords=""
    />
);
